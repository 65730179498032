<template>
  <div class="container page">
    <div class="row justify-content-center">
        <div class="col-md-6 text-center">
            <h3>Welcome to R'lyeh! Praise Cthulhu!</h3>
            <img class="img-fluid img-cthulhu" src="/cthulhu_500.webp" fetchpriority="high" width="500" height="500" alt="Cthulhu Architect Wants You!"/>
            <h4>Iä R'lyea! Cthulhu ftagn! Iä! Iä!</h4>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Email Verify",
    components: {  },
    data() {
      return {
        id: '',
        hash: '',
        signature: '',
        expires: ''
      };
    },
    created() {
      this.id = this.$route.params.id
      this.hash = this.$route.params.hash
      this.signature = this.$route.query.signature
      this.expires = this.$route.query.expires

      this.verifyEmail();
    },
    methods: {
      verifyEmail() {
        // Handle forgot password logic here
        this.$http.get("/sanctum/csrf-cookie").then(() => {
          this.$http
            .get("/email/verify/" + this.id + '/' + this.hash, {
              params: {
                expires: this.expires,
                signature: this.signature
              }
            })
            .then(() => {
              // Reset password link sent successfully
              // Show success message or redirect to a confirmation page
              this.$toast.success("Your email has been verified!");

              // set timeout of 5 seconds and then redirect to /
              setTimeout(() => {
                window.location.href = '/';
              }, 3000);
            })
        });
      },
    },
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 60px;
  margin-bottom:30px;
  font-weight:bold;
}

h4 {
  font-weight:bold;
  margin-top:30px;
  margin-bottom:60px;
}
</style>
